<template>
  <div>
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary text-bold">
            Washir Terms of Use
          </h2>
          <b-card-text class="mb-2 text-bold">
            Updated March 02, 2021
          </b-card-text>

        </b-card-body>
      </b-card>
    </section>

    <div class="container">
      <b-card
        no-body
      >
        <b-card-body>

          <p>CAREFULLY READ THESE TERMS AND CONDITIONS BEFORE ACCESSING OR USING OUR SERVICES BECAUSE THEY GOVERN YOUR ACCESS TO AND USE OF THE WASHIR APPLICATION, PLATFORM AND AUTHORIZED SERVICES. IT IS IMPORTANT THAT YOU READ AND UNDERSTAND THE FOLLOWING TERMS.
            BY ACCESSING THE WASHIR WEBSITE AND/OR MOBILE APP, YOU AGREE THAT THESE TERMS WILL APPLY IF YOU CHOOSE TO ACCESS OR USE THE SERVICE AND RECEIVE SERVICES.
            <br>
            Washir provides applications for the on-demand delivery of on-site, Mobile on-demand detailing and washing
          </p>

          <p>
            The Services permit you to utilize the certain Internet and mobile-device applications, including storing personal content regarding your vehicle(s), eMail, and payment information and accessing it on your devices and computers, only under the terms and conditions outlined in this Agreement. As soon as you enable Washir, your content will be automatically sent to and stored by Washir, so you can receive professional, on-demand auto detailing services that are insured, convenient and efficient.
          </p>
          <h3 class="mb-1 mt-3">
            Contractual Relationship
          </h3>

          <p>
            These Terms of Service ("Terms") govern your individual set-up, use and access from within the United States and its territories and possessions of the applications, websites, content, products, and services (the "Services") made available in the United States and its territories and possessions by Washir, Inc. and its affiliates, (collectively, "Washir"). The words "including" and "include" mean "including, but not limited to" in these Terms.
          </p>
          <p>
            Your access and utilized of the Services constitutes your agreement to be bound by these Terms, which establishes a contractual relationship between you and Washir. If you do not conform to these Terms of use, you may not access or utilize the Services. These Terms expressly supersede prior agreements or arrangements with you. Washir may immediately terminate these Terms or any Services concerning you, or cease offering or deny access to the Services or any portion thereof, at any time for any reason.
          </p>

          <p>
            Supplemental terms may apply to certain Services, such as policies for a particular event, activity or promotion, and such supplemental terms will be disclosed to you in connection with the applicable Service(s). Supplemental terms are in addition to and shall be deemed a part of, the Terms for the applicable Service(s). Supplemental terms shall prevail over these Terms in the event of conflict concerning the applicable Services.
          </p>

          <p>

            Washir may amend the Terms related to the Services from time to time. Amendments will be effective upon Washir's posting of such updated Terms at this location or the amended policies or supplemental terms on the applicable Service(s). Your continued access or use of the Services after such posting constitutes your consent to be bound by the Terms, as amended.
          </p>
          <p>
            Our collection and use of personal information in connection with the Services is as provided in Washir's Privacy Policy located at https://washir/privacy.
          </p>

          <h3 class="mb-1 mt-3">
            The Authorized Services
          </h3>

          <p>
            The Services constitute a technology platform that enables users ("Authorized Service Recipients") of Washir's mobile applications or websites provided as part of the Services (each, an "Application") to arrange and schedule on-demand mobile-detailing and washing services with other users or third party providers of such services ("Service Providers" or "Third Party Providers"). Unless otherwise agreed by Washir in a separate written agreement with you, the Services are made available solely for your personal, non-commercial use.
          </p>

          <p>
            YOU ACKNOWLEDGE THAT WASHIR AS A PLATFORM PROVIDER DOES NOT PROVIDE MOBILE-DETAILING OR WASHING SERVICES OR FUNCTION AS AN MOBILE-DETAILING OR WASHING COMPANY.
          </p>

          <h3 class="mb-1 mt-3">
            Devices and Accounts
          </h3>
          <p>
            Use of the Service may require compatible devices, Internet access, and certain software (fees may apply); may require periodic updates, and may be affected by the performance of these factors.
          </p>

          <h3 class="mb-1 mt-3">
            License
          </h3>
          <p>
            Subject to your compliance with these Terms, Washir grants you a limited, non-exclusive, non-sub-licensable, revocable, non-transferrable license to: (i) access and use the Applications on your personal device solely in connection with your use of the Services; and (ii) access and use any content, information and related materials that may be made available through the Services, in each case solely for your personal, non-commercial use. Any rights not expressly granted herein are reserved by Washir and Washir's licensors.
          </p>

          <h3 class="mb-1 mt-3">
            Authorized Brands and Services
          </h3>
          <p>
            You acknowledge that portions of the Services may be made available under Washir's various brands or request options associated with mobile-detailing and washing, including on-demand mobile-detailing request brands currently referred to as "Washir," "PerfectWashir", "ProWashir".
          </p>
          <p>
            You, Authorized Service Recipient, also acknowledge that the Services may be made available under such brands or request options by or in connection with: (i) certain of Washir's subsidiaries and affiliates; or (ii) independent Service Providers and Third Party Providers, including Auto-Detailing drivers, Auto-Detailing permit holders or holders of similar auto-detailing permits, authorizations or licenses.
          </p>

          <h3 class="mb-1 mt-3">
            Restrictions
          </h3>
          <p>
            You may not: (i) remove any copyright, trademark or other proprietary notices from any portion of the Services; (ii) reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services except as expressly permitted by Washir; (iii) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Services or unduly burdening or hindering the operation and/or functionality of any aspect of the Services; (iv) link to, mirror or frame any portion of the Services; (v) decompile, reverse engineer or disassemble the Services except as may be permitted by applicable law or (vi) attempt to gain unauthorized access to or impair any aspect of the Services or its related systems or networks.
          </p>

          <h3 class="mb-1 mt-3">
            Copyright and Other Rights of Third-Party Services and Content
          </h3>
          <p>
            The Services may be made available or accessed in connection with third-party services and content (including advertising) that Washir does not control. You acknowledge that different terms of use and privacy policies may apply to your use of such third party technology services and digital content. Washir does not endorse such third party technology services and digital content and in no event shall Washir be responsible or liable for any products or services of such third party providers. Additionally, Apple Inc., Google, Inc., Microsoft Corporation or BlackBerry Limited will be a third-party beneficiary to this contract if you access the Services using Applications developed for Apple iOS, Android, Microsoft Windows, or Blackberry-powered mobile devices, respectively. These third-party beneficiaries are not parties to this contract and are not responsible for the provision or support of the Services in any manner. Your access to the Services using these devices is subject to terms outlined in the terms and conditions applicable to those other technology-based, third-party beneficiary's terms of service.
          </p>

          <h3 class="mb-1 mt-3">
            Ownership
          </h3>
          <p>
            The Services and all rights therein are and shall remain Washir's property or the property of Washir's licensors. Neither these Terms nor your use of the Services convey or grant to you any rights: (i) in or related to the Services except for the limited license granted above; or (ii) to use or reference in any manner Washir's company names, logos, product and service names, trademarks or services marks or those of Washir's licensors.
          </p>

          <h3 class="align-left">
            Your Use of the Services
          </h3>
          <h3 class="mb-1 mt-3">
            Authorized Service Recipient's User Accounts
          </h3>
          <p>
            To use most aspects of the Services, you must register for and maintain an active personal user Services account ("Account"). You must be at least 18 years of age, or the age of legal majority in your jurisdiction (if different than 18), to obtain an Account. Account registration requires you to submit to Washir certain personal information, such as your name, address, mobile phone number, and age, as well as at least one valid payment method (either a credit card or accepted payment partner). You agree to maintain accurate, complete, and up-to-date information in your Account. Your failure to maintain accurate, complete, and up-to-date Account information, including having an invalid or expired payment method on file, may result in your inability to access and use the Services or Washir may terminate this Agreement with you and suspend your Account functionality. You are responsible for all activity that occurs under your Account, and you agree to maintain the security and secrecy of your Account's username and password at all times. Unless otherwise permitted by Washir in writing, you may only possess one Account.
          </p>

          <h3 class="mb-1 mt-3">
            User Requirements and Conduct
          </h3>
          <p>
            The Service is not available for use by persons under the age of 18. You may not authorize third parties to use your Account, and you may not allow persons under the age of 18 to receive auto-detailing services from Service Providers or Third Party Providers unless you accompany them. You may not assign or otherwise transfer your Account to any other person or entity. You agree to comply with all applicable laws when using the Services, and you may only use the Services for lawful purposes (e.g., no transport of unlawful or hazardous materials). You will not in your use of the Services cause nuisance, annoyance, inconvenience, or property damage, whether to the Third Party Provider or any other party; especially the public. In certain instances, you may be asked to provide proof of identity to access or use the Services, and you agree that you may be denied access to or use of the Services if you refuse to provide proof of identity.
          </p>

          <h3 class="mb-1 mt-3">
            User Provided Content
          </h3>
          <p>
            Washir may, in Washir's sole discretion, permit you from time to time to submit, upload, publish or otherwise make available to Washir through the Services textual, audio, and/or visual content and information, including commentary and feedback related to the Services, initiation of support requests, and submission of entries for competitions and promotions ("Authorized Service Recipient or Service Provider Content"). Any Authorized Service Recipient or Service Provider Content provided by you remains your property. However, by providing Authorized Service Recipient or Service Provider Content to Washir, you grant Washir a worldwide, perpetual, irrevocable, transferrable, royalty-free license, with the right to sublicense, to use, copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such Authorized Service Recipient or Service Provider Content in all formats and distribution channels now known or hereafter devised (including in connection with the Services and Washir's business and on third-party sites and services), without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.
          </p>
          <p>
            You represent and warrant that: (i) you either are the sole and exclusive owner of all Authorized Service Recipient or Service Provider Content or you have all rights, licenses, consents and releases necessary to grant Washir the license to the Authorized Service Recipient or Service Provider Content as set forth above; and (ii) neither the Authorized Service Recipient or Service Provider Content nor your submission, uploading, publishing or otherwise making available of such Authorized Service Recipient or Service Provider Content nor Washir's use of the Authorized Service Recipient or Service Provider Content as permitted herein will infringe, misappropriate or violate a third party's intellectual property or proprietary rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.
          </p>
          <p>
            You agree not to provide Authorized Service Recipient or Service Provider Content that is defamatory, libelous, hateful, violent, obscene, pornographic, unlawful, or otherwise offensive, as determined by Washir in its sole discretion, whether or not such material may be protected by law. Washir may, but shall not be obligated to, review, monitor, or remove Authorized Service Recipient or Service Provider Content at Washir's sole discretion and at any time and for any reason, without notice to you.
          </p>

          <h3 class="mb-1 mt-3">
            Network Access and Devices
          </h3>
          <p>
            You are responsible for obtaining the data network access necessary to use the Services. Your mobile network's data and messaging rates and fees may apply if you access or use the Services from a wireless-enabled device. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Services and Applications and any updates to it. Washir does not guarantee that the Services, or any portion thereof, will function on any particular hardware or devices. Also, the Services may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.
          </p>

          <h3 class="mb-1 mt-3">
            Payment
          </h3>
          <p>
            You understand that use of the Services may result in charges to you for the services or goods you receive from a Service Provider or other Third Party Provider ("Charges"). We charge the credit card and hold the charge when users request a service. In case of cancelation or service was not available in requested time and criteria, refund will follow immediately. After you have received services or goods obtained through your use of the Service, Washir will facilitate your payment of the applicable Charges on behalf of the Service Provider or Third Party Provider, as such Service Provider's or Third Party Provider's limited payment collection agent. Payment of the Charges in such manner shall be considered the same as a payment made directly by you to the Service Provider or Third Party Provider. Charges will be inclusive of applicable taxes where required by law. Charges paid by you are final and non-refundable unless otherwise determined by Washir. You retain the right to request lower Charges from a Service Provider or Third Party Provider for services or goods received by you from such Service Provider or Third Party Provider at the time you receive such services or goods. Washir will respond accordingly to any request from a Service Provider or Third Party Provider to modify the Charges for a particular service or good.
          </p>
          <p>
            All Charges are due immediately, and payment will be facilitated by Washir using the preferred payment method designated in your Account. If your primary Account payment method is determined to be expired, invalid or otherwise not able to be charged, you agree that Washir may, as the Service Provider or Third Party Provider's limited payment collection agent, use a secondary payment method in your Account, if available.
          </p>
          <p>
            As between you and Washir, Washir reserves the right to establish, remove and revise Charges for any or all services or goods obtained through the use of the Services at any time in Washir's sole discretion. Further, you acknowledge and agree that Charges applicable in certain geographical areas may increase substantially during times of high demand. Washir will use reasonable efforts to inform you of Charges that may apply, provided that you will be responsible for Charges incurred under your Account regardless of your awareness of such Charges or the amounts thereof. Washir may from time to time, provide certain users with promotional offers and discounts that may result in different amounts charged for the same or similar services or goods obtained through the use of the Services, and you agree that such promotional offers and discounts, unless also made available to you, shall have no bearing on your use of the Services or the Charges applied to you. You may elect to cancel your request for services or goods from a Service Provider or Third Party Provider at any time before such Service Provider or Third Party Provider's arrival, in which case you may be charged a cancellation fee.
          </p>
          <p>
            This payment structure is intended to fully compensate the Service Provider or Third Party Provider for the services or goods provided. Except concerning auto-detailing services requested through the Application, Washir does not designate any portion of your payment as a tip or gratuity to the Service Provider or Third Party Provider. Any representation by Washir (on Washir's website, in the Application, or in Washir's marketing materials) to the effect that tipping is "voluntary," "not required," and/or "included" in the payments you make for services or goods provided is not intended to suggest that Washir provides any additional amounts, beyond those described above, to the Service Provider or Third Party Provider. You understand and agree that, while you are free to provide additional payment as a gratuity to any Service Provider or Third Party Provider who provides you with services or goods obtained through the Service, you are under no obligation to do so. Gratuities are voluntary. After you have received services or goods obtained through the Service, you will have the opportunity to rate your experience and leave additional feedback about your Service Provider or Third Party Provider.
          </p>
          <h3 class="mb-1 mt-3">
            DISCLAIMER
          </h3>
          <p>
            SERVICES PROVIDED "AS AVAILABLE" AND "AS IS." WASHIR MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, OR AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. WASHIR DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY PROVIDERS.
          </p>
          <p>
            ALSO, WASHIR DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION IN ADDITION TO THAT, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
          </p>

          <h3 class="mb-1 mt-3">
            LIMITATION OF LIABILITY
          </h3>
          <p>
            EVEN IF WASHIR HAS BEEN ADVISED OF THE POSSIBILITY OF THE FOLLOWING DAMAGES, WASHIR SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES.
          </p>
          <p>
            WASHIR SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, EVEN IF WASHIR HAS BEEN ADVISED OF THE POSSIBILITY. WASHIR SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND WASHIR'S REASONABLE CONTROL. YOU ACKNOWLEDGE THAT THIRD PARTY AUTO-DETAILING PROVIDERS PROVIDING AUTO-DETAILING SERVICES REQUESTED THROUGH SOME REQUEST BRANDS MAY OFFER RIDESHARING OR PEER-TO-PEER AUTO-DETAILING SERVICES AND MAY NOT BE PROFESSIONALLY LICENSED OR PERMITTED. IN NO EVENT SHALL WASHIR'S TOTAL LIABILITY TO YOU IN CONNECTION WITH THE SERVICES FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION EXCEED FIVE HUNDRED U.S. DOLLARS ($500).
          </p>
          <p>
            WASHIR'S SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE AUTO-DETAILING, GOODS, OR SERVICES WITH SERVICE PROVIDERS AND THIRD-PARTY PROVIDERS, BUT YOU AGREE THAT WASHIR HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY AUTO-DETAILING, GOODS OR SERVICES PROVIDED TO YOU BY SERVICE PROVIDERS AND THIRD-PARTY PROVIDERS ABOVE WHAT IS EXPRESSLY SET FORTH IN THESE TERMS OF USE.
          </p>
          <h3 class="mb-1 mt-3">
            Indemnity
          </h3>
          <p>
            You agree to hold and indemnify Washir and its officers, employees, directors, and agents harmless from any and all claims, losses, liabilities, demands, and expenses (including attorneys' fees), arising out of or in connection with: (i) your use of the Services or services or goods obtained through your use of the Services; (ii) your breach or violation of any of these Terms; (iii) Washir's use of your User Content; or (iv) your violation of the rights of any third party, including Third Party Providers.
          </p>

          <h3 class="align-left">
            Dispute Resolution
          </h3>
          <h3 class="mb-1 mt-3">
            Arbitration
          </h3>
          <p>
            You agree that any controversy, claim or dispute arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Services (collectively, "Disputes") will be settled by binding arbitration between you and Washir, except that each party retains the right to bring an individual action in small claims court and the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights, trade secrets, trademarks, patents or other intellectual property rights. You acknowledge and agree that you and Washir are each waiving the right to a trial by jury or to participate as a plaintiff or class in any purported class action or representative proceeding. Furthermore, unless both you and Washir agree otherwise in writing, the arbitrator cannot consolidate the claims of more than one person. Otherwise, it cannot be governed by any form of class or representative procedure. If this particular paragraph is held unenforceable, the whole of this "Dispute resolution" section is deemed void. Except as prescribed in the preceding paragraph, this "Dispute resolution" section will continue to terminate this agreement.
          </p>

          <h3 class="align-left">
            Other Provisions
          </h3>
          <h3 class="mb-1 mt-3">
            Choice of Law
          </h3>
          <p>
            These Terms are governed by and construed by the laws of the State of California, U.S.A., without giving effect to any conflict of law principles.
          </p>

          <h3 class="mb-1 mt-3">
            Intellectual Property Infringement
          </h3>
          <p>
            Claims of copyright or Intellectual Property Infringement should be sent to Washir's designated agent. Please visit Washir's web page at [https://washir.com] for the designated address and additional information.
          </p>

          <h3 class="mb-1 mt-3">
            Notice
          </h3>
          <p>
            Washir may give general notice about the service. You may use an email to your account's email address, or a written notification sent by a prepaid post to your address in the first class mail or your account. This kind of notification deemed to be given at the expiry of 48 hours after mailing or posting (if sent by first-class mail or pre-paid post) or 12 hours after transmission (if sent by e-mail). You may give notice to Washir, with such notice deemed given when received by Washir, at any time by first class mail or pre-paid post to Washir.
          </p>

          <h3 class="mb-1 mt-3">
            General
          </h3>
          <p>
            You may not assign these Terms without Washir's prior written approval. Washir may assign these Terms without your consent to (i) a subsidiary or affiliate; (ii) a successor by merger or (iii) an acquirer of Washir's equity, business or assets. Any steps in violation of this section shall be invalid. No joint venture, employment, partnership, or agency relationship exists between you, Washir or any Third Party Provider as a result of this Agreement or use of the Services. If any provision of these terms is retained to be void or unenforceable, such provision shall be struck, and the remaining provisions shall be applied to the fullest under the law. Washir's failure to enforce any right or provision of these Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to by Washir in writing.
          </p>

        </b-card-body>
      </b-card>
    </div>

  </div>

</template>

<script>
import {

  BCard,
  BCardBody,
  BCardText

} from 'bootstrap-vue'

export default {
  name: 'Terms',
  components: {
    BCard,
    BCardBody,
    BCardText
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
